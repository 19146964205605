import * as React from 'react'
import { css } from 'emotion'

interface MagazineProps {
  link: string
}

export class Magazine extends React.Component<MagazineProps> {
  render() {
    return (<></>) //deleted now.
  }
}
